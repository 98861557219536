<template>
  <ValidationObserver rules="required" v-slot="{ handleSubmit }">
    <RightSideBar
      v-if="openModal"
      :loading="loading"
      @submit="handleSubmit(submit)"
      submit="Apply"
      @close="$emit('close'), (forms = [])"
      button-class="bg-dynamicBackBtn text-white"
    >
      <template v-slot:title>
        <p class="text-darkPurple capitalize">
          Rating
        </p>
      </template>
      <div>
        <table aria-label="table" class=" w-full mt-4">
          <thead>
            <tr>
              <th
                class="uppercase"
                id="header"
                v-for="(header, index) in headers"
                :key="index"
              >
                <p>{{ header.title }}</p>
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(data, i) in forms"
              :key="i"
              class="tableBody text-sm w-full "
              style="height: 48px; "
            >
              <td class="w-1/6">
                <c-text
                  placeholder="--Enter--"
                  type="number"
                  min="0"
                  v-model="data.rating"
                />
              </td>
              <td class="w-3/4">
                <v-select
                  :clearable="false"
                  placeholder="--Enter--"
                  class="style-select capitalize"
                  style="height: 32px"
                  taggable
                  v-model="data.description"
                  :options="criterias"
                  :filterable="false"
                >
                </v-select>
              </td>
              <td />
              <td v-if="forms.length > 1">
                <Icon
                  size="s"
                  @click.native="removeForm(i)"
                  class="text-flame cursor-pointer"
                  icon-name="close"
                />
              </td>
            </tr>
            <tr />
            <tr>
              <button class="flex ml-6 mt-6" @click="addForm">
                <Icon size="s" icon-name="icon-plus" />
                <p class="ml-3 text-flame font-semibold">
                  Add
                </p>
              </button>
            </tr>
            <tr class="h-7" />
          </tbody>
        </table>
      </div>
    </RightSideBar>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import "vue-select/dist/vue-select.css";

export default {
  name: "Rating",
  components: {
    ValidationObserver,
    RightSideBar: () => import("@/components/RightSideBar"),
    vSelect: () => import("vue-select"),
    CText: () => import("@scelloo/cloudenly-ui/src/components/text")
  },
  props: {
    openModal: {
      type: Boolean,
      default: false
    },
    modalType: {
      type: String,
      default: "addition"
    },
    ratingSettings: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      forms: [],
      openDelete: false,
      itemId: "",
      itemIndex: 0,
      criterias: [
        "Poor",
        "Very Poor",
        "Below Average",
        "Average",
        "Good",
        "Exceptional"
      ],
      headers: [{ title: "Rating" }, { title: "Description" }, { title: "" }],
      loading: false
    };
  },
  watch: {
    openModal() {
      if (this.openModal) {
        if (this.ratingSettings.length > 0) {
          this.forms = this.ratingSettings;
        } else {
          this.addForm();
        }
      }
    }
  },
  methods: {
    addForm() {
      this.forms.push({
        rating: null,
        description: null
      });
    },
    removeForm(index) {
      if (index > 0 || this.forms.length > 1) this.forms.splice(index, 1);
    },
    submit() {
      this.$emit("ratingForm", this.forms);
      this.$emit("close");
      this.forms = [];
    }
  }
};
</script>

<style scoped>
tr:nth-child(even) {
  background-color: white;
}
.wrapper {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
